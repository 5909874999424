import axios from "@/libs/request";
/**
 * 获取动账记录详情
 */
export const PostLogin = (data, config = {}) => {
  return axios.request({
    url: `/user/auth`,
    method: "post",
    data,
    ...config,
  });
};
