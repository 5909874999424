<template>
  <div class="login_contarner">
    <div class="title">欢迎使用睿珀管理系统</div>
    <div class="login_box" @keyup.enter="login">
      <!-- title区域 -->
      <div>
        <el-link class="title1" @click="LoginPwd" :underline="false">
          <div v-if="activeLogin == 0" style="color: blue">账号登录</div>
          <div v-else>账号登录</div>
        </el-link>
        <span class="title3">|</span>
        <el-link class="title2" @click="LoginCode" :underline="false">
          <div v-if="activeLogin == 1" style="color: blue">扫码登录</div>
          <div v-else>扫码登录</div></el-link
        >
      </div>
      <!-- 登录表单区域,loginFormRef即为表单的引用对象 -->
      <el-form
        :model="loginForm"
        :rules="loginFormRules"
        label-width="0px"
        class="login_form"
        ref="loginFormRef"
        v-show="activeLogin == 0"
      >
        <!-- 用户名 +字体图标 可引入第三方图标库-->
        <el-form-item prop="username">
          <el-input
            v-model="loginForm.username"
            prefix-icon="el-icon-user-solid"
            placeholder="请输入用户名"
            clearable
          ></el-input>
        </el-form-item>
        <!-- 密码 prop指定校验规则-->
        <el-form-item prop="password">
          <el-input
            show-password
            v-model="loginForm.password"
            prefix-icon="el-icon-unlock"
            type="password"
            placeholder="请输入密码"
            clearable
          ></el-input>
        </el-form-item>

        <!--  -->
        <div class="ifcheck">
          <el-checkbox v-model="isRemember">记住账号密码？</el-checkbox>
        </div>
        <!-- 按钮 -->
        <el-form-item class="btns">
          <el-button type="primary" @click="login">登录</el-button>
          <el-button type="info" @click="resetloginForm">重置</el-button>
        </el-form-item>
      </el-form>
      <el-form class="login_form" v-show="activeLogin == 1">暂未开放</el-form>
    </div>
  </div>
</template>
<script>
import { PostLogin } from '@/api/service/auth';

export default {
  data () {
    return {
      // 登录表单数据绑定对象，事先使用:model定义，如何采用v-model调用，以下都会调用此对象
      loginForm: {
        username: '',
        password: '',
      },
      activeLogin: 0,
      // 表单的验证规则对象
      loginFormRules: {
        username: [
          { required: true, message: '用户名不能为空', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ]
      },
      isRemember: false
    }
  },
  // 按enter键登录
  created () {
    document.onkeydown = function (e) {
      e = window.event || e
      if (e.code === 'Enter' || e.code === 'enter') {
        this.login()
      }
    },
      this.IFCookie()
  },
  // 点击重置按钮，重置登录表单
  methods: {
    resetloginForm () {
      // this.$refs.loginFormRef.resetFields()
      this.loginForm = {}
    },
    LoginPwd () { this.activeLogin = 0 },
    LoginCode () { this.activeLogin = 1 },
    async login () {
      // 登录之前的预验证,后台对接后加入以下步骤*********************
      this.$refs.loginFormRef.validate(async valid => {
        if (valid) {
          if (this.isRemember) {
            // 判断 如果勾选记住密码
            this.setCookies(this.loginForm.username, this.loginForm.password, 7)
          } else {
            // 否则 清空cookies
            this.clearCookies()
          }
          window.localStorage.setItem('isRemember', this.isRemember)

          const res = await PostLogin(this.loginForm, {
            loading: { target: document.querySelector('.login_box')}
          })
          // 将用户token保存到vuex中
          // console.log(res.data.data)
          this.userToken = res.password
          window.sessionStorage.setItem('token', this.userToken)
          window.sessionStorage.setItem('id', res.id)

          this.$message({
            message: '登录成功',
            type: 'success',
            offset: 100
          })
          window.sessionStorage.setItem('username', this.loginForm.username)

          // window.sessionStorage.setItem('token', '1')
          this.$router.push('/home')
        }
      })
    },
    // 是否保存用户名密码
    setCookies (username, password, exDays = 60) {
      // Encrypt，加密账号密码
      var cipherUsername = this.CryptoJS.AES.encrypt(username + '', 'secretKey').toString()
      var cipherPsw = this.CryptoJS.AES.encrypt(password + '', 'secretKey').toString()
      // 获取时间
      let exDate = new Date()
      // 保存的天数
      exDate.setTime(exDate.getTime() + 24 * 60 * 60 * 1000 * exDays)
      window.document.cookie = `username =${cipherUsername};path=/;expires = ${exDate.toUTCString()}`
      window.document.cookie = `password =${cipherPsw};path=/;expires = ${exDate.toUTCString()}`
    },
    getCookies () {
      let cipherUsername = this.$cookies.get(`username`)
      let cipherPsw = this.$cookies.get(`password`)
      // 将用户名解码
      let decryptUsername = this.CryptoJS.AES.decrypt(cipherUsername, `secretKey`).toString(this.CryptoJS.enc.Utf8)
      // 将密码解码
      let decryptPwd = this.CryptoJS.AES.decrypt(cipherPsw, `secretKey`).toString(this.CryptoJS.enc.Utf8)
      // 将账号密码付给form
      this.loginForm.username = decryptUsername
      this.loginForm.password = decryptPwd
    },
    clearCookies () {
      this.$cookies.remove(`username`)
      this.$cookies.remove(`password`)
    },
    IFCookie () {
      if (window.localStorage.getItem('isRemember') === 'true') {
        this.getCookies()
        this.isRemember = true
      }
    }
  }
}
</script>
<style lang="less" scoped>
.login_contarner {
  background-image: url("../../assets/imgs/u0.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
  width: 100%;
  // position: fixed;
  .title {
    color: #080a79;
    font-size: 30px;
    text-align: center;
    position: absolute;
    top: 18%;
    left: 44%;
    width: 320px;
    text-shadow: 0px 0px 10px #ccc;
  }
  .login_box {
    width: 450px;
    height: 400px;
    background-color: #fff;
    border-radius: 20px;
    position: absolute;
    left: 40%;
    top: 28%;
    // transform: translate(-50%);
    .title1 {
      color: #0c0c0c;
      font-size: 20px;
      text-align: center;
      position: absolute;
      top: 12%;
      left: 24%;
      text-shadow: 0px 0px 10px rgb(236, 225, 225);
    }
    .title2 {
      color: #0c0c0c;
      font-size: 20px;
      text-align: center;
      position: absolute;
      top: 12%;
      left: 54%;
      text-shadow: 0px 0px 10px rgb(236, 225, 225);
    }
    .title3 {
      color: #0c0c0c;
      font-size: 20px;
      text-align: center;
      position: absolute;
      top: 12%;
      left: 47%;
      text-shadow: 0px 0px 10px rgb(236, 225, 225);
    }
  }
  .login_form {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    top: 30%;
    font-size: 20px;
  }
  .btns {
    // 居右对齐
    display: flex;
    justify-content: flex-end;
    justify-content: center;
    position: relative;
    top: 15%;
  }
  .ifcheck {
    text-align: left;
  }
  .ifcheck1 {
    position: relative;
    top: 15%;
    text-align: center;
  }
}
</style>
